import { graphql } from 'gatsby';
import Template from '../template';

export default Template;

export const pageQuery = graphql`
  query($id: String!) {
    article: strapiBlogArticle(id: {eq: $id}) {
      ...DetailArticleFragment
    }
    labelsAndMessages {
      en
    }
  }
`;
